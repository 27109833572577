import { IUrlResolver } from "app/common/UrlResolver";
import { WidgetsConfiguration } from "types/Models";

import * as ko from "knockout";

import { ProductWidgetDefinition } from "app/models/ProductWidgetDefinition";
import { IWidgetFactory } from "app/models/IWidgetFactory";
import { ProductWidget } from "app/models/ProductWidget";
import { IEventAggregator } from "app/common/EventAggregator";
import { ShoppingFacade } from "app/models/ShoppingFacade";
import { ITranslationService } from "app/models/TranslationService";
import { IUpsellService } from "app/models/UpsellWidget";
import { ILoadingIndicator } from "app/models/GlobalLoaderWidget";
import { PlaceableWidgetFactoryBase as WidgetFactoryBase } from "./PlaceableWidgetFactoryBase";

export class ProductWidgetFactory extends WidgetFactoryBase  implements IWidgetFactory {

    private readonly shoppingFacade: ShoppingFacade;
    private readonly eventAggregator: IEventAggregator;
    private readonly translationService: ITranslationService;
    private readonly urlResolver: IUrlResolver;
    private readonly configuration: WidgetsConfiguration;
    private readonly upsellService: IUpsellService;
    private readonly loader: ILoadingIndicator;

    constructor(shoppingFacade: ShoppingFacade, eventAggregator: IEventAggregator, translationService: ITranslationService,
		urlResolver: IUrlResolver, configuration: WidgetsConfiguration, upsellService: IUpsellService, loader: ILoadingIndicator) {
	    super();
        this.shoppingFacade = shoppingFacade;
        this.eventAggregator = eventAggregator;
        this.translationService = translationService;
        this.urlResolver = urlResolver;
        this.configuration = configuration;
        this.upsellService = upsellService;
        this.loader = loader;
    }

    build(definition: ProductWidgetDefinition): any {
	    const element = this.getElement(definition);
        // TODO: add css binding for displaying loader

        const itembody = document.getElementById("skfWidget__productInitial").innerText;
        element.innerHTML = itembody;

        try {

            const model = new ProductWidget(definition.articleNumber,
                definition.resellerUrl, this.configuration.isAxios,
                this.shoppingFacade,
                this.eventAggregator,
                this.translationService,
                this.upsellService,
                this.loader,
            );

			ko.applyBindings(model,element);
            //ko.applyBindings(model, document.getElementById(definition.containerId));
            return model;
        } catch (e) {
            console.error(e);
            throw e;
        }   
    }
}